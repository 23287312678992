import React from 'react'
import "./Home.css"
import Header from './Header'
function Home() {
  return (
    <div>
        <Header />
        <main className="site-content" id="content">
              <section className="hero-section d-flex align-items-center" id="intro">
                    <div className="intro_text">
                        <svg viewBox="0 0 1320 300">
                          <text x="50%" y="50%" textAnchorr="middle" className="animate-stroke">US</text>
                        </svg>
                    </div>
                    <div className="container">
                        <div className="row align-items-center">
                          <div className="col-md-6">
                              <div className="hero-content-box">
                                <span className="hero-sub-title">Hi, I am "Yasir Sohel"</span>
                                 
                                 <div id="slideset6">
                                    <span>Web Developer</span>
                                    <span>Graphics Designer</span>
                                    <span>Digital Marketing</span>
                                 </div>

                                  


                                <div className="hero-image-box d-md-none text-center">
                                    <img src="assets/img/hero-img2.png" alt="" />
                                </div>

                                <p className="lead">
                                    I am a professional web developer. I can provide clean code and pixel perfect design.
                                    I also make website more & more interactive with web animations.
                                </p>
                                <div className="button-box d-flex flex-wrap align-items-center">
                                    <a href="#" className="btn tj-btn-secondary">Download CV <i className="flaticon-download"></i></a>
                                    <ul className="ul-reset social-icons">
                                      <li>
                                          <a href="#"><i className="fa-brands fa-twitter"></i></a>
                                      </li>
                                      <li>
                                          <a href="#"><i className="fa-light fa-basketball"></i></a>
                                      </li>
                                      <li>
                                          <a href="#"><i className="fa-brands fa-linkedin-in"></i></a>
                                      </li>
                                      <li>
                                          <a href="#"><i className="fa-brands fa-github"></i></a>
                                      </li>
                                    </ul>
                                </div>
                              </div>
                          </div>
                          <div className="col-md-6 d-none d-md-block">
                              <div className="hero-image-box text-center">
                                <img src="assets/img/hero-img2.png" alt="" />
                              </div>
                          </div>
                        </div>
                        <div className="funfact-area">
                          <div className="row">
                            <div class="slider">
                              <div class="slide-track">
                                <div class="slide">
                                  <img src="assets/img/client_logo/client_logo0.png"  />
                                </div>
                                <div class="slide">
                                  <img src="assets/img/client_logo/client_logo1.png"  />
                                </div>
                               <div class="slide">
                                  <img src="assets/img/client_logo/client_logo4.png"  />
                                </div>
                                <div class="slide">
                                  <img src="assets/img/client_logo/client_logo5.png"  />
                                </div>
                                <div class="slide">
                                  <img src="assets/img/client_logo/client_logo6.png"  />
                                </div>
                                <div class="slide">
                                  <img src="assets/img/client_logo/client_logo7.png"  />
                                </div>
                                <div class="slide">
                                  <img src="assets/img/client_logo/client_logo8.png"  />
                                </div>
                                <div class="slide">
                                  <img src="assets/img/client_logo/client_logo10.png"  />
                                </div>
                                <div class="slide">
                                  <img src="assets/img/client_logo/client_logo11.png"  />
                                </div>
                                <div class="slide">
                                  <img src="assets/img/client_logo/client_logo12.png"  />
                                </div>
                                <div class="slide">
                                  <img src="assets/img/client_logo/client_logo13.png"  />
                                </div>
                                <div class="slide">
                                  <img src="assets/img/client_logo/client_logo14.png"  />
                                </div>
                                <div class="slide">
                                  <img src="assets/img/client_logo/client_logo16.png"  />
                                </div>
                                <div class="slide">
                                  <img src="assets/img/client_logo/client_logo17.png"  />
                                </div>
                                <div class="slide">
                                  <img src="assets/img/client_logo/client_logo18.png"  />
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                    </div>
              </section>

              <section className="facilities-section" id="facilities-section">
                  <div className="container">
                  <div className="row">
                        <div className="col-md-12">
                            <div className="section-header text-center">
                              <h2 className="section-title wow fadeInUp" data-wow-delay=".3s" style={{visibility: 'hidden' , animationDelay: '0.3s' , animationName: 'none'}}><i className="flaticon-recommendation"></i> Facilities</h2>
                              <p className="wow fadeInUp" data-wow-delay=".4s" style={{visibility: 'hidden' , animationDelay: '0.4s' , animationName: 'none'}}>
                                  We put your ideas and thus your wishes in the form of a unique web project that inspires you and
                                  you customers.
                              </p>
                            </div>
                        </div>
                      </div>

                  <div className="row">
                          <div className="col-md-6">
                              <div className="resume-widget">
                                <div className="resume-item wow fadeInLeft" data-wow-delay=".4s" style={{visibility: 'hidden' , animationDelay: '0.4s' , animationName: 'none'}}>
                                    <div className="time">24/7 ONLINE SUPPORT</div>
                                    <h3 className="resume-title">Support Facility</h3>
                                    <div className="institute">24/7 Online Support- We always listen to our customer. Any time they are in a problem, our team is always online for providing support to them. Even at night, we are online for your projects ongoing.</div>
                                </div>
                                <div className="resume-item wow fadeInLeft" data-wow-delay=".5s" style={{visibility: 'hidden' , animationDelay: '0.5s' , animationName: 'none'}}>
                                    <div className="time">FASTEST DELIVERY</div>
                                    <h3 className="resume-title">Delivery Facility</h3>
                                    <div className="institute">We provide to customers any product fast delivery between customers deadline. And any problem of project get our experts’ guideline and maintain it in international standard.</div>
                                </div>
                                <div className="resume-item wow fadeInLeft" data-wow-delay=".6s" style={{visibility: 'hidden' , animationDelay: '0.6s' , animationName: 'none'}}>
                                    <div className="time">LIVE CORRECTION</div>
                                    <h3 className="resume-title">Correction Facility</h3>
                                    <div className="institute">24/7 Online Support and live support software correction of our customer. Any time they are in a problem, our team is always providing live correction support to them.</div>
                                </div>
                               </div>
                          </div>

                          <div className="col-md-6">
                              <div className="resume-widget">
                                <div className="resume-item wow fadeInRight" data-wow-delay=".5s" style={{visibility: 'hidden' , animationDelay: '0.5s' , animationName: 'none'}}>
                                    <div className="time">LIFETIME SUPPORT</div>
                                    <h3 className="resume-title">Time Facility</h3>
                                    <div className="institute">All U.Signe customers get supports for life time. For any problem of project get our experts’ guideline and maintain it in international standard.</div>
                                </div>
                                <div className="resume-item wow fadeInRight" data-wow-delay=".6s" style={{visibility: 'hidden' , animationDelay: '0.6s' , animationName: 'none'}}>
                                    <div className="time">USER-FRIENDLY APPLICATIONS</div>
                                    <h3 className="resume-title">Device Facility</h3>
                                    <div className="institute">We provide standard technology and maintain it in international standard. So customer can use this friendly in any device or any block.</div>
                                </div>
                                <div className="resume-item wow fadeInRight" data-wow-delay=".7s" style={{visibility: 'hidden' , animationDelay: '0.7s' , animationName: 'none'}}>
                                    <div className="time">LATEST TECHNOLOGIES</div>
                                    <h3 className="resume-title">Update Facility</h3>
                                    <div className="institute">We provide latest technology and maintain it in international standard. So customer can use this friendly in any device or any block.</div>
                                </div>
                               </div>
                          </div>
                        </div>
                  </div>
                </section>


              


              <section className="services-section" id="services-section">
                  <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                            <div className="section-header text-center">
                              <h2 className="section-title wow fadeInUp" data-wow-delay=".3s" style={{visibility: 'hidden' , animationDelay: '0.3s' , animationName: 'none'}}><i className="flaticon-graduation-cap"></i> My Quality Services</h2>
                              <p className="wow fadeInUp" data-wow-delay=".4s" style={{visibility: 'hidden' , animationDelay: '0.4s' , animationName: 'none'}}>
                              Our all Services are Featured, outstanding and Innovative.These featured are below.
                              </p>
                            </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                            <div className="services-widget position-relative">
                              <div className="service-item current d-flex flex-wrap align-items-center wow fadeInUp" data-wow-delay=".5s" style={{visibility: 'hidden' , animationDelay: '0.5s' , animationName: 'none'}}>
                                  <div className="left-box d-flex flex-wrap align-items-center">
                                    <span className="number">01</span>
                                    <h3 className="service-title">Web Design & Develop</h3>
                                  </div>
                                  <div className="right-box">
                                    <p>
                                        Great design is a rare combination of user experience, art direction, and marketing know-how. And it’s not just about aesthetics. Good design makes a huge difference to your visitors, and your bottom line.
                                    </p>
                                  </div>
                                  <i className="flaticon-up-right-arrow"></i>
                                  <button data-mfp-src="#service-wrapper" className="service-link modal-popup"></button>
                              </div>
                              <div className="service-item d-flex flex-wrap align-items-center wow fadeInUp mleave" data-wow-delay=".6s" style={{visibility: 'hidden' , animationDelay: '0.6s' , animationName: 'none'}}>
                                  <div className="left-box d-flex flex-wrap align-items-center">
                                    <span className="number">02</span>
                                    <h3 className="service-title">E-commerce Solution</h3>
                                  </div>
                                  <div className="right-box">
                                    <p>
                                        U.Signe is a leading IT Company, specializing in e-commerce development (online shopping carts, web stores), SEO and Internet Marketing. It has been working huge in e-commerce sector since last 5 years.
                                    </p>
                                  </div>
                                  <i className="flaticon-up-right-arrow"></i>
                                  <button data-mfp-src="#service-wrapper" className="service-link modal-popup"></button>
                              </div>
                              <div className="service-item d-flex flex-wrap align-items-center wow fadeInUp mleave" data-wow-delay=".7s" style={{visibility: 'hidden' , animationDelay: '0.7s' , animationName: 'none'}}>
                                  <div className="left-box d-flex flex-wrap align-items-center">
                                    <span className="number">03</span>
                                    <h3 className="service-title">UI/UX Design</h3>
                                  </div>
                                  <div className="right-box">
                                    <p>
                                        U.Signe specializes in creating unique, high-end UI/UX Design. Our talented team is constantly striving to deliver products that exceed your expectations.
                                    </p>
                                  </div>
                                  <i className="flaticon-up-right-arrow"></i>
                                  <button data-mfp-src="#service-wrapper" className="service-link modal-popup"></button>
                              </div>
                              <div className="service-item d-flex flex-wrap align-items-center wow fadeInUp mleave" data-wow-delay=".8s" style={{visibility: 'hidden' , animationDelay: '0.8s' , animationName: 'none'}}>
                                  <div className="left-box d-flex flex-wrap align-items-center">
                                    <span className="number">04</span>
                                    <h3 className="service-title">Graphics Design</h3>
                                  </div>
                                  <div className="right-box">
                                    <p>
                                        U.Signe specializes in creating unique, high-end graphic designs. Our talented team is constantly striving to deliver products that exceed your expectations.</p>
                                  </div>
                                  <i className="flaticon-up-right-arrow"></i>
                                  <button data-mfp-src="#service-wrapper" className="service-link modal-popup"></button>
                              </div>
                              <div className="service-item d-flex flex-wrap align-items-center wow fadeInUp mleave" data-wow-delay=".8s" style={{visibility: 'hidden' , animationDelay: '0.8s' , animationName: 'none'}}>
                                  <div className="left-box d-flex flex-wrap align-items-center">
                                    <span className="number">05</span>
                                    <h3 className="service-title">Content Writing</h3>
                                  </div>
                                  <div className="right-box">
                                    <p>
                                        This program associates with script writing, cinematography, editing, production and directing in broadcast, web production, advertisement and others industries.</p>
                                  </div>
                                  <i className="flaticon-up-right-arrow"></i>
                                  <button data-mfp-src="#service-wrapper" className="service-link modal-popup"></button>
                              </div>
                              <div className="service-item d-flex flex-wrap align-items-center wow fadeInUp mleave" data-wow-delay=".8s" style={{visibility: 'hidden' , animationDelay: '0.8s' , animationName: 'none'}}>
                                  <div className="left-box d-flex flex-wrap align-items-center">
                                    <span className="number">06</span>
                                    <h3 className="service-title">Digital Marketing</h3>
                                  </div>
                                  <div className="right-box">
                                    <p>
                                        The Digital Filmmaking Program is designed for those who are interested in building an exciting career in the field of film making and media production.</p>
                                  </div>
                                  <i className="flaticon-up-right-arrow"></i>
                                  <button data-mfp-src="#service-wrapper" className="service-link modal-popup"></button>
                              </div>
                              <div className="active-bg wow fadeInUp mleave" data-wow-delay=".5s" style={{ top: '0px' , height: '108.8px' , visibility: 'hidden' , animationDelay: '0.5s' , animationName: 'none' }}></div>
                            </div>
                        </div>
                      </div>
                  </div>
                </section>


                <div id="service-wrapper" className="popup_content_area zoom-anim-dialog mfp-hide">
                    <div className="popup_modal_img">
                        <img src="assets/img/portfolio/personal_pic.png" alt="" />
                    </div>

                    <div className="popup_modal_content">
                        <div className="service_details">
                          <div className="row">
                              <div className="col-lg-7 col-xl-8">
                                <div className="service_details_content">
                                    <div className="service_info">
                                      <h6 className="subtitle">SERVICES</h6>
                                      <h2 className="title">An IT Solution Provider</h2>
                                      <div className="desc">
                                          <p>
                                          UniSigne provides web design and development to credit unions that want to
                                          enhance their brand and grow and connect their membership.
                                          </p>

                                          <p>
                                          UniSigne Group is an interactive agency and design firm that specializes in the conception,
                                          design and programming of interactive media and web technologies for credit unions worldwide.
                                          We provide comprehensive web design and development services — from simple, brochure web sites to
                                          sophisticated enterprise mega-sites.
                                          </p>

                                          <h2 className="title">What We Do</h2>
                                          <p>
                                          In today’s dynamic marketplace, it is vital that your company has access to the most up-to-date
                                          Internet technologies. Our web development solutions are developed with your business processes in
                                          mind, offering your business a means for running more smoothly and efficiently. We take measures to
                                          ensure that your company’s online infrastructure is secure and running productively.
                                          </p>
                                      </div>

                                      <br/>
                                      <br/>
                                      <h3 className="title">We prepare your projects in 5 stages</h3>
                                      
                                      <img src="assets/img/services_process.png" alt="" />
                                      
                                    </div>
                                </div>
                              </div>
                              <div className="col-lg-5 col-xl-4">
                                <div className="tj_main_sidebar">
                                    <div className="sidebar_widget services_list">
                                      <div className="widget_title">
                                          <h3 className="title">All Services</h3>
                                      </div>
                                      <ul>
                                          <li className="active">
                                            <button>
                                                <i className="flaticon-design"></i>
                                                Web Design
                                            </button>
                                          </li>
                                          <li>
                                            <button>
                                                <i className="flaticon-3d-movie"></i>
                                                E-Commerce Solution
                                            </button>
                                          </li>
                                          <li>
                                            <button>
                                                <i className="flaticon-ux-design"></i>
                                                UI/UX Design
                                            </button>
                                          </li>
                                          <li>
                                            <button>
                                                <i className="flaticon-web-design"></i>
                                                Graphics Design
                                            </button>
                                          </li>
                                          <li>
                                            <button>
                                                <i className="flaticon-ui-design"></i>
                                                Digital Marketing
                                            </button>
                                          </li>
                                      </ul>
                                    </div>

                                    <div className="sidebar_widget contact_form">
                                      <div className="widget_title">
                                          <h3 className="title">Get in Touch</h3>
                                      </div>

                                      <form action="https://themejunction.net/html/gerold/demo/index.html">
                                          <div className="form_group">
                                            <input type="text" name="name" id="name" placeholder="Name" autoComplete="off" />
                                          </div>
                                          <div className="form_group">
                                            <input type="email" name="semail" id="semail" placeholder="Email" autoComplete="off" />
                                          </div>
                                          <div className="form_group">
                                            <textarea name="smessage" id="smessage" placeholder="Your message" autoComplete="off"></textarea>
                                          </div>
                                          <div className="form_btn">
                                            <button className="btn tj-btn-primary" type="submit">Send Message</button>
                                          </div>
                                      </form>
                                    </div>
                                </div>
                              </div>
                          </div>
                          </div>
                          <div className="portfolio_navigation" style={{marginTop:'30px'}}>
                          
                        <div className="copy-text" style={{width:'100%' , textAlign:'center'}}><p>© 2024 All rights reserved by <a href="https://unisigne.com" target="_blank">UniSigne</a></p></div>
                        </div>
                        
                    </div>
                    
                </div>

                <section className="portfolio-section" id="works-section">
                    <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                              <div className="section-header text-center">
                                <h2 className="section-title wow fadeInUp" data-wow-delay=".3s" style={{visibility: 'hidden' , animationDelay: '0.3s' , animationName: 'none'}}>My Recent Few Works</h2>
                                <p className="wow fadeInUp" data-wow-delay=".4s" style={{visibility: 'hidden' , animationDelay: '0.4s' , animationName: 'none'}}>
                                We are Committed to our customers giving 100% satisfied product service and
                                Our main goal is to help customer.
                                </p>
                              </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                              <div className="portfolio-filter text-center wow fadeInUp" data-wow-delay=".5s" style={{visibility: 'hidden' , animationDelay: '0.5s' , animationName: 'none'}}>
                                <div className="button-group filter-button-group">
                                    <button data-filter="*" className="active">All</button>
                                    <button data-filter=".ecommerce" className=".portfolio-filter .button-group">E-Commerce</button>
                                    <button data-filter=".corporate" className=".portfolio-filter .button-group">Corporate</button>
                                    <button data-filter=".construction" className=".portfolio-filter .button-group">Construction</button>
                                    <button data-filter=".education" className=".portfolio-filter .button-group">Education</button>
                                    <div className="active-bg .portfolio-filter .button-group" style={{left: '8px' , width: '69.8375px'}}></div>
                                </div>
                              </div>

                              <div className="portfolio-box wow fadeInUp" data-wow-delay=".6s" style={{position: 'relative' , height: '1169.46px' , visibility: 'hidden' , animationDelay: '0.6s' , animationName: 'none'}}>
                                <div className="portfolio-sizer"></div>
                                <div className="gutter-sizer"></div>
                                <div className="portfolio-item ecommerce" style={{position: 'absolute' , left: '0%' , top: '50px'}}>
                                    <div className="image-box">
                                      <img src="assets/img/portfolio/ecom1.jpg" alt="" />
                                    </div>
                                    <div className="content-box">
                                      <h3 className="portfolio-title">E-commerce</h3>
                                      <i className="flaticon-up-right-arrow"></i>
                                      <button data-mfp-src="#portfolio-wrapper" className="portfolio-link modal-popup"></button>
                                    </div>
                                </div>
                                <div className="portfolio-item corporate" style={{position: 'absolute' , left: '51.9994%' , top: '50px'}}>
                                    <div className="image-box">
                                      <img src="assets/img/portfolio/cor1.jpg" alt="" />
                                    </div>
                                    <div className="content-box">
                                      <h3 className="portfolio-title">Corporate</h3>
                                      <i className="flaticon-up-right-arrow"></i>
                                      <button data-mfp-src="#portfolio-wrapper" className="portfolio-link modal-popup"></button>
                                    </div>
                                </div>
                                <div className="portfolio-item ecommerce" style={{position: 'absolute' , left: '51.9994%' , top: '608.788px'}}>
                                    <div className="image-box">
                                      <img src="assets/img/portfolio/ecom2.jpg" alt="" />
                                    </div>
                                    <div className="content-box">
                                      <h3 className="portfolio-title">E-commerce</h3>
                                      <i className="flaticon-up-right-arrow"></i>
                                      <button data-mfp-src="#portfolio-wrapper" className="portfolio-link modal-popup"></button>
                                    </div>
                                </div>
                                <div className="portfolio-item corporate" style={{position: 'absolute' , left: '0%' , top: '608.788px'}}>
                                    <div className="image-box">
                                      <img src="assets/img/portfolio/cor2.jpg" alt="" />
                                    </div>
                                    <div className="content-box">
                                      <h3 className="portfolio-title">Corporate</h3>
                                      <i className="flaticon-up-right-arrow"></i>
                                      <button data-mfp-src="#portfolio-wrapper" className="portfolio-link modal-popup"></button>
                                    </div>
                                </div>
                                <div className="portfolio-item ecommerce" style={{position: 'absolute' , left: '51.9994%' , top: '608.788px'}}>
                                    <div className="image-box">
                                      <img src="assets/img/portfolio/ecom3.jpg" alt="" />
                                    </div>
                                    <div className="content-box">
                                      <h3 className="portfolio-title">E-commerce</h3>
                                      <i className="flaticon-up-right-arrow"></i>
                                      <button data-mfp-src="#portfolio-wrapper" className="portfolio-link modal-popup"></button>
                                    </div>
                                </div>
                                <div className="portfolio-item corporate" style={{position: 'absolute' , left: '51.9994%' , top: '608.788px'}}>
                                    <div className="image-box">
                                      <img src="assets/img/portfolio/cor3.jpg" alt="" />
                                    </div>
                                    <div className="content-box">
                                      <h3 className="portfolio-title">Corporate</h3>
                                      <i className="flaticon-up-right-arrow"></i>
                                      <button data-mfp-src="#portfolio-wrapper" className="portfolio-link modal-popup"></button>
                                    </div>
                                </div>
                                <div className="portfolio-item education" style={{position: 'absolute' , left: '51.9994%' , top: '608.788px'}}>
                                    <div className="image-box">
                                      <img src="assets/img/portfolio/edu1.jpg" alt="" />
                                    </div>
                                    <div className="content-box">
                                      <h3 className="portfolio-title">Education</h3>
                                     <i className="flaticon-up-right-arrow"></i>
                                      <button data-mfp-src="#portfolio-wrapper" className="portfolio-link modal-popup"></button>
                                    </div>
                                </div>
                                <div className="portfolio-item construction" style={{position: 'absolute' , left: '51.9994%' , top: '608.788px'}}>
                                    <div className="image-box">
                                      <img src="assets/img/portfolio/cons1.jpg" alt="" />
                                    </div>
                                    <div className="content-box">
                                      <h3 className="portfolio-title">Construction</h3>
                                      <i className="flaticon-up-right-arrow"></i>
                                      <button data-mfp-src="#portfolio-wrapper" className="portfolio-link modal-popup"></button>
                                    </div>
                                </div>
                                <div className="portfolio-item ecommerce" style={{position: 'absolute' , left: '51.9994%' , top: '608.788px'}}>
                                    <div className="image-box">
                                      <img src="assets/img/portfolio/ecom4.jpg" alt="" />
                                    </div>
                                    <div className="content-box">
                                      <h3 className="portfolio-title">E-commerce</h3>
                                      <i className="flaticon-up-right-arrow"></i>
                                      <button data-mfp-src="#portfolio-wrapper" className="portfolio-link modal-popup"></button>
                                    </div>
                                </div>
                                <div className="portfolio-item corporate" style={{position: 'absolute' , left: '51.9994%' , top: '608.788px'}}>
                                    <div className="image-box">
                                      <img src="assets/img/portfolio/cor4.jpg" alt="" />
                                    </div>
                                    <div className="content-box">
                                      <h3 className="portfolio-title">Corporate</h3>
                                      <i className="flaticon-up-right-arrow"></i>
                                      <button data-mfp-src="#portfolio-wrapper" className="portfolio-link modal-popup"></button>
                                    </div>
                                </div>
                                <div className="portfolio-item ecommerce" style={{position: 'absolute' , left: '51.9994%' , top: '608.788px'}}>
                                    <div className="image-box">
                                      <img src="assets/img/portfolio/ecom5.jpg" alt="" />
                                    </div>
                                    <div className="content-box">
                                      <h3 className="portfolio-title">E-commerce</h3>
                                      <i className="flaticon-up-right-arrow"></i>
                                      <button data-mfp-src="#portfolio-wrapper" className="portfolio-link modal-popup"></button>
                                    </div>
                                </div>
                                <div className="portfolio-item construction" style={{position: 'absolute' , left: '51.9994%' , top: '608.788px'}}>
                                    <div className="image-box">
                                      <img src="assets/img/portfolio/cons4.jpg" alt="" />
                                    </div>
                                    <div className="content-box">
                                      <h3 className="portfolio-title">Construction</h3>
                                      <i className="flaticon-up-right-arrow"></i>
                                      <button data-mfp-src="#portfolio-wrapper" className="portfolio-link modal-popup"></button>
                                    </div>
                                </div>
                                <div className="portfolio-item education" style={{position: 'absolute' , left: '51.9994%' , top: '608.788px'}}>
                                    <div className="image-box">
                                      <img src="assets/img/portfolio/edu2.jpg" alt="" />
                                    </div>
                                    <div className="content-box">
                                      <h3 className="portfolio-title">Education</h3>
                                     <i className="flaticon-up-right-arrow"></i>
                                      <button data-mfp-src="#portfolio-wrapper" className="portfolio-link modal-popup"></button>
                                    </div>
                                </div>
                                <div className="portfolio-item ecommerce" style={{position: 'absolute' , left: '51.9994%' , top: '608.788px'}}>
                                    <div className="image-box">
                                      <img src="assets/img/portfolio/ecom6.jpg" alt="" />
                                    </div>
                                    <div className="content-box">
                                      <h3 className="portfolio-title">E-commerce</h3>
                                     <i className="flaticon-up-right-arrow"></i>
                                      <button data-mfp-src="#portfolio-wrapper" className="portfolio-link modal-popup"></button>
                                    </div>
                                </div>
                                <div className="portfolio-item corporate" style={{position: 'absolute' , left: '51.9994%' , top: '608.788px'}}>
                                    <div className="image-box">
                                      <img src="assets/img/portfolio/cor5.jpg" alt="" />
                                    </div>
                                    <div className="content-box">
                                      <h3 className="portfolio-title">Corporate</h3>
                                      <i className="flaticon-up-right-arrow"></i>
                                      <button data-mfp-src="#portfolio-wrapper" className="portfolio-link modal-popup"></button>
                                    </div>
                                </div>
                                <div className="portfolio-item ecommerce" style={{position: 'absolute' , left: '51.9994%' , top: '608.788px'}}>
                                    <div className="image-box">
                                      <img src="assets/img/portfolio/ecom7.jpg" alt="" />
                                    </div>
                                    <div className="content-box">
                                      <h3 className="portfolio-title">E-commerce</h3>
                                      <i className="flaticon-up-right-arrow"></i>
                                      <button data-mfp-src="#portfolio-wrapper" className="portfolio-link modal-popup"></button>
                                    </div>
                                </div>
                                <div className="portfolio-item corporate" style={{position: 'absolute' , left: '51.9994%' , top: '608.788px'}}>
                                    <div className="image-box">
                                      <img src="assets/img/portfolio/cor6.jpg" alt="" />
                                    </div>
                                    <div className="content-box">
                                      <h3 className="portfolio-title">Corporate</h3>
                                      <i className="flaticon-up-right-arrow"></i>
                                      <button data-mfp-src="#portfolio-wrapper" className="portfolio-link modal-popup"></button>
                                    </div>
                                </div>
                                <div className="portfolio-item ecommerce" style={{position: 'absolute' , left: '51.9994%' , top: '608.788px'}}>
                                    <div className="image-box">
                                      <img src="assets/img/portfolio/ecom8.jpg" alt="" />
                                    </div>
                                    <div className="content-box">
                                      <h3 className="portfolio-title">E-commerce</h3>
                                      <i className="flaticon-up-right-arrow"></i>
                                      <button data-mfp-src="#portfolio-wrapper" className="portfolio-link modal-popup"></button>
                                    </div>
                                </div>
                              </div>
                          </div>
                        </div>
                    </div>
                </section>

                <div id="portfolio-wrapper" className="popup_content_area zoom-anim-dialog mfp-hide">
                    <div className="popup_modal_img">
                        <img src="assets/img/portfolio/personal_pic.png" alt="" />
                    </div>

                    <div className="popup_modal_content">
                        <div className="portfolio_info">
                          <div className="portfolio_info_text">
                              <div className='pop_img' style={{width:'170px' , paddingBottom:'25px'}}><img src="assets/img/logo.png" alt="" /></div>
                              <div className="desc">
                                <p>
                                Uni9Signe provides web design and development to credit unions that want to enhance their brand
                                and grow and connect their membership.
                                </p>
                              </div>
                              <a href="#home-section" className="btn tj-btn-primary">Go Home <i className="fal fa-arrow-right"></i></a>
                          </div>
                          <div className="portfolio_info_items">
                              <div className="info_item">
                                <div className="key">Category</div>
                                <div className="value">Web Design</div>
                              </div>
                              <div className="info_item">
                                <div className="key">Client</div>
                                <div className="value">Artboard Studio</div>
                              </div>
                              <div className="info_item">
                                <div className="key">Start Date</div>
                                <div className="value">August 20, 2023</div>
                              </div>
                              <div className="info_item">
                                <div className="key">Designer</div>
                                <div className="value"><a href="#">ThemeJunction</a></div>
                              </div>
                          </div>
                        </div>

                        <div className="portfolio_gallery owl-carousel owl-loaded owl-drag">
                          
                          
                          
                          
                        <div className="owl-stage-outer">
                          <div className="owl-stage" style={{transition: 'all 0s ease 0s'}}>
                              <div className="owl-item">
                                  <div className="gallery_item">
                                    <img src="assets/img/portfolio/ecom1.jpg" alt="" />
                                </div>
                              </div>
                              <div className="owl-item">
                                <div className="gallery_item">
                                    <img src="assets/img/portfolio/cor1.jpg" alt="" />
                                </div>
                              </div>
                              <div className="owl-item">
                                  <div className="gallery_item">
                                    <img src="assets/img/portfolio/cons4.jpg" alt="" />
                                </div>
                              </div>
                              <div className="owl-item">
                                  <div className="gallery_item">
                                    <img src="assets/img/portfolio/edu1.jpg" alt="" />
                                </div>
                              </div>
                          </div>
                        </div>
                          <div className="owl-nav disabled"><button type="button" role="presentation" className="owl-prev"><span aria-label="Previous">‹</span></button><button type="button" role="presentation" className="owl-next"><span aria-label="Next">›</span></button></div><div className="owlt-dots"><button role="button" className="owl-dot active"><span></span></button><button role="button" className="owl-dot"><span></span></button><button role="button" className="owl-dot"><span></span></button><button role="button" className="owl-dot"><span></span></button></div></div>



                        <div className="portfolio_navigation" style={{marginTop:'30px'}}>
                          
                        <div className="copy-text" style={{width:'100%' , textAlign:'center'}}><p>© 2024 All rights reserved by <a href="https://unisigne.com" target="_blank">UniSigne</a></p></div>
                        </div>
                    </div>
                </div>


                


                <section className="resume-section" id="resume-section">
                    <div className="container">
                        <div className="row">
                          <div className="col-md-6">
                              <div className="section-header wow fadeInUp" data-wow-delay=".3s" style={{visibility: 'hidden' , animationDelay: '0.3s' , animationName: 'none'}}>
                                <h2 className="section-title"><i className="flaticon-recommendation"></i> My Experience</h2>
                              </div>

                              <div className="resume-widget">
                                <div className="resume-item wow fadeInLeft" data-wow-delay=".4s" style={{visibility: 'hidden' , animationDelay: '0.4s' , animationName: 'none'}}>
                                    <div className="time">2013-2015</div>
                                    <h3 className="resume-title">Web Designer</h3>
                                    <div className="institute">The Prolancer</div>
                                </div>
                                <div className="resume-item wow fadeInLeft" data-wow-delay=".5s" style={{visibility: 'hidden' , animationDelay: '0.5s' , animationName: 'none'}}>
                                    <div className="time">2015-2017</div>
                                    <h3 className="resume-title">SENIOR WEB DEVELOPER</h3>
                                    <div className="institute">BizBend IT</div>
                                </div>
                                <div className="resume-item wow fadeInLeft" data-wow-delay=".6s" style={{visibility: 'hidden' , animationDelay: '0.6s' , animationName: 'none'}}>
                                    <div className="time">2018 - 2021</div>
                                    <h3 className="resume-title">SENIOR WEB DEVELOPER & TEAM LEADER</h3>
                                    <div className="institute">GSTech LTD</div>
                                </div>
                              
                              </div>
                          </div>

                          <div className="col-md-6">
                              <div className="section-header wow fadeInUp" data-wow-delay=".4s" style={{visibility: 'hidden' , animationDelay: '0.4s' , animationName: 'none'}}>
                                <h2 className="section-title"><i className="flaticon-graduation-cap"></i> My Education</h2>
                              </div>

                              <div className="resume-widget">
                                <div className="resume-item wow fadeInRight" data-wow-delay=".5s" style={{visibility: 'hidden' , animationDelay: '0.5s' , animationName: 'none'}}>
                                    <div className="time">2002</div>
                                    <h3 className="resume-title">Secondary School Certificate (SSC)</h3>
                                    <div className="institute">Batisha High School</div>
                                </div>
                                <div className="resume-item wow fadeInRight" data-wow-delay=".6s" style={{visibility: 'hidden' , animationDelay: '0.6s' , animationName: 'none'}}>
                                    <div className="time">2002 - 2004</div>
                                    <h3 className="resume-title">Higher Secondary Certificate (HSC)</h3>
                                    <div className="institute">Chouddagram Collage</div>
                                </div>
                                <div className="resume-item wow fadeInRight" data-wow-delay=".7s" style={{visibility: 'hidden' , animationDelay: '0.7s' , animationName: 'none'}}>
                                    <div className="time">2005 - 2009</div>
                                    <h3 className="resume-title">Bachelor of Business Studies (BBS)</h3>
                                    <div className="institute">Al-Haj Nur Mia University</div>
                                </div>
                                
                              </div>
                          </div>
                        </div>
                    </div>
                </section>
        
        

                <section className="team-section" id="team-section">
                    <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-header text-center">
                              <h2 className="section-title wow fadeInUp" data-wow-delay=".3s" style={{visibility: 'hidden' , animationDelay: '0.3s' , animationName: 'none'}}><i className="flaticon-graduation-cap"></i> My Team</h2>
                              <p className="wow fadeInUp" data-wow-delay=".4s" style={{visibility: 'hidden' , animationDelay: '0.4s' , animationName: 'none'}}>
                              Our honorable creative team members.
                              </p>
                            </div>
                        </div>
                      </div>
                        <div className="row">
                          <div className="col-md-4">
                              <div className="resume-widget">
                                <div className="resume-item wow fadeInLeft" data-wow-delay=".4s" style={{visibility: 'hidden' , animationDelay: '0.4s' , animationName: 'none'}}>
                                <div className="img-box"><img src="assets/img/team-img-1.png" alt="" /></div>
                                    <div className="time">Sohel Yasir</div>
                                    <h3 className="resume-title">Developer Web</h3>
                                    <div className="social">
                                      <ul>
                                        <li><a href="#"><i className="fa-brands fa-facebook"></i></a></li>
                                        <li><a href="#"><i className="fa-brands fa-twitter"></i></a></li>
                                        <li><a href="#"><i className="fa-brands fa-linkedin-in"></i></a></li>
                                      </ul>
                                    </div>
                                </div>
                               </div>
                          </div>

                          <div className="col-md-4">
                              <div className="resume-widget">
                                <div className="resume-item wow fadeInLeft" data-wow-delay=".4s" style={{visibility: 'hidden' , animationDelay: '0.4s' , animationName: 'none'}}>
                                <div className="img-box"><img src="assets/img/team-img-2.png" alt="" /></div>
                                    <div className="time">Fahim Muntasir</div>
                                    <h3 className="resume-title">Graphics Designer</h3>
                                    <div className="social">
                                      <ul>
                                        <li><a href="#"><i className="fa-brands fa-facebook"></i></a></li>
                                        <li><a href="#"><i className="fa-brands fa-twitter"></i></a></li>
                                        <li><a href="#"><i className="fa-brands fa-linkedin-in"></i></a></li>
                                      </ul>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div className="col-md-4">
                              <div className="resume-widget">
                                <div className="resume-item wow fadeInRight" data-wow-delay=".5s" style={{visibility: 'hidden' , animationDelay: '0.5s' , animationName: 'none'}}>
                                <div className="img-box"><img src="assets/img/team-img-3.png" alt="" /></div>
                                    <div className="time">Nazmus Sakib</div>
                                    <h3 className="resume-title">Digital Marketer</h3>
                                    <div className="social">
                                      <ul>
                                        <li><a href="#"><i className="fa-brands fa-facebook"></i></a></li>
                                        <li><a href="#"><i className="fa-brands fa-twitter"></i></a></li>
                                        <li><a href="#"><i className="fa-brands fa-linkedin-in"></i></a></li>
                                      </ul>
                                    </div>
                                </div>
                               </div>
                          </div>
                        </div>
                    </div>
                </section>




                <section className="skills-section" id="skills-section">
                    <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                              <div className="section-header text-center">
                                <h2 className="section-title wow fadeInUp" data-wow-delay=".3s" style={{visibility: 'hidden' , animationDelay: '0.3s' , animationName: 'none'}}>My Skills</h2>
                                <p className="wow fadeInUp" data-wow-delay=".4s" style={{visibility: 'hidden' , animationDelay: '0.4s' , animationName: 'none'}}>
                                    We put your ideas and thus your wishes in the form of a unique web project that inspires you and
                                    you customers.
                                </p>
                              </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                              <div className="skills-widget d-flex flex-wrap justify-content-center align-items-center">
                                <div className="skill-item wow fadeInUp" data-wow-delay=".3s" style={{visibility: 'hidden' , animationDelay: '0.3s' , animationName: 'none'}}>
                                    <div className="skill-inner">
                                      <div className="icon-box">
                                          <img src="assets/img/htm_css.png" alt="" />
                                      </div>
                                      <div className="number">99%</div>
                                    </div>
                                    <p>Html/CSS</p>
                                </div>
                                <div className="skill-item wow fadeInUp" data-wow-delay=".4s" style={{visibility: 'hidden' , animationDelay: '0.4s' , animationName: 'none'}}>
                                    <div className="skill-inner">
                                      <div className="icon-box">
                                          <img src="assets/img/php.png" alt="" />
                                      </div>
                                      <div className="number">80%</div>
                                    </div>
                                    <p>PHP</p>
                                </div>
                                <div className="skill-item wow fadeInUp" data-wow-delay=".5s" style={{visibility: 'hidden' , animationDelay: '0.5s' , animationName: 'none'}}>
                                    <div className="skill-inner">
                                      <div className="icon-box">
                                          <img src="assets/img/wp.svg" alt="" />
                                      </div>
                                      <div className="number">99%</div>
                                    </div>
                                    <p>WordPress</p>
                                </div>
                                <div className="skill-item wow fadeInUp" data-wow-delay=".6s" style={{visibility: 'hidden' , animationDelay: '0.6s' , animationName: 'none'}}>
                                    <div className="skill-inner">
                                      <div className="icon-box">
                                          <img src="assets/img/react.svg" alt="" />
                                      </div>
                                      <div className="number">80%</div>
                                    </div>
                                    <p>React</p>
                                </div>
                                <div className="skill-item wow fadeInUp" data-wow-delay=".7s" style={{visibility: 'hidden' , animationDelay: '0.7s' , animationName: 'none'}}>
                                    <div className="skill-inner">
                                      <div className="icon-box">
                                          <img src="assets/img/mysql.png" alt="" />
                                      </div>
                                      <div className="number">89%</div>
                                    </div>
                                    <p>MySQL</p>
                                </div>
                                <div className="skill-item wow fadeInUp" data-wow-delay=".8s" style={{visibility: 'hidden' , animationDelay: '0.8s' , animationName: 'none'}}>
                                    <div className="skill-inner">
                                      <div className="icon-box">
                                          <img src="assets/img/js.svg" alt="" />
                                      </div>
                                      <div className="number">75%</div>
                                    </div>
                                    <p>JavaScript</p>
                                </div>
                              </div>
                          </div>
                        </div>
                    </div>
                </section>
                

                <section className="testimonial-section" id="testimonials-section">
                      <div className="container">
                          <div className="row">
                            <div className="col-lg-5">
                                <div className="section-header">
                                  <h2 className="section-title wow fadeInLeft" data-wow-delay=".3s" style={{visibility: 'hidden' , animationDelay: '0.3s' , animationName: 'none'}}>My Client's Stories</h2>
                                  <p className="wow fadeInLeft" data-wow-delay=".4s" style={{visibility: 'hidden' , animationDelay: '0.4s' , animationName: 'none'}}>
                                  Lets see what our recent customer says about our service.</p>
                                </div>
                            </div>
                            <div className="col-lg-7 col-xl-6 offset-xl-1">
                                <div className="testimonials-widget wow fadeInRight" data-wow-delay=".5s" style={{visibility: 'hidden' , animationDelay: '0.4s' , animationName: 'none'}}>
                                  <div className="owl-carousel testimonial-carousel owl-loaded owl-drag">
                                      
                                      
                                      
                                      
                                      
                                      
                                  <div className="owl-stage-outer"><div className="owl-stage" style={{transform: 'translate3d(-999px, 0px, 0px)' , transition: 'all 0s ease 0s' , width: '3996px'}}>
                                      <div className="owl-item cloned" style={{width: '303px' , marginRight: '30px'}}><div className="testimonial-item">
                                          <div className="top-area d-flex flex-wrap justify-content-between">
                                              <div className="logo-box">
                                                <img src="assets/img/2.png" alt="" />
                                              </div>
                                              <div className="image-box">
                                                <img src="assets/img/me.png" alt="" />
                                              </div>
                                          </div>
                                          <div className="icon-box">
                                              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.105431 2.18998C0.0301532 0.988687 1.02531 -0.00647222 2.2266 0.0688056L19.4961 1.15097C21.2148 1.25867 22.0029 3.34358 20.7852 4.56127L4.5979 20.7486C3.3802 21.9663 1.2953 21.1781 1.1876 19.4594L0.105431 2.18998Z" fill="url(#paint0_linear_263_514)"></path>
                                                <defs>
                                                    <lineargradient id="paint0_linear_263_514" x1="-0.0363755" y1="-0.0729998" x2="35.3333" y2="-0.0729991" gradientUnits="userSpaceOnUse">
                                                      <stop offset="1" stopColor="var(--tj-theme-primary)"></stop>
                                                      <stop offset="1" stopColor="#140C1C" stopOpacity="0"></stop>
                                                    </lineargradient>
                                                </defs>
                                              </svg>
                                              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.105431 2.18998C0.0301532 0.988687 1.02531 -0.00647222 2.2266 0.0688056L19.4961 1.15097C21.2148 1.25867 22.0029 3.34358 20.7852 4.56127L4.5979 20.7486C3.3802 21.9663 1.2953 21.1781 1.1876 19.4594L0.105431 2.18998Z" fill="url(#paint0_linear_263_515)"></path>
                                                <defs>
                                                    <lineargradient id="paint0_linear_263_515" x1="-0.0363755" y1="-0.0729998" x2="35.3333" y2="-0.0729991" gradientUnits="userSpaceOnUse">
                                                      <stop offset="1" stopColor="var(--tj-theme-primary)"></stop>
                                                      <stop offset="1" stopColor="#140C1C" stopOpacity="0"></stop>
                                                    </lineargradient>
                                                </defs>
                                              </svg>
                                          </div>
                                          <p className="quote">Had worked with U.Signe before, on this project and will again in the future. Great guy, easy to work with! Always a pleasure! 5***** developer!</p>
                                          <h4 className="name">Adam Poul</h4>
                                          <span className="designation">SEO Specialist, Theme Junction</span>
                                        </div>
                                      </div>
                                      <div className="owl-item cloned" style={{width: '303px' , marginRight: '30px'}}><div className="testimonial-item">
                                          <div className="top-area d-flex flex-wrap justify-content-between">
                                              <div className="logo-box">
                                                <img src="assets/img/1.png" alt="" />
                                              </div>
                                              <div className="image-box">
                                                <img src="assets/img/2(2).jpg" alt="" />
                                              </div>
                                          </div>
                                          <div className="icon-box">
                                              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.105431 2.18998C0.0301532 0.988687 1.02531 -0.00647222 2.2266 0.0688056L19.4961 1.15097C21.2148 1.25867 22.0029 3.34358 20.7852 4.56127L4.5979 20.7486C3.3802 21.9663 1.2953 21.1781 1.1876 19.4594L0.105431 2.18998Z" fill="url(#paint0_linear_263_512)"></path>
                                                <defs>
                                                    <lineargradient id="paint0_linear_263_512" x1="-0.0363755" y1="-0.0729998" x2="35.3333" y2="-0.0729991" gradientUnits="userSpaceOnUse">
                                                      <stop offset="1" stopColor="var(--tj-theme-primary)"></stop>
                                                      <stop offset="1" stopColor="#140C1C" stopOpacity="0"></stop>
                                                    </lineargradient>
                                                </defs>
                                              </svg>
                                              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.105431 2.18998C0.0301532 0.988687 1.02531 -0.00647222 2.2266 0.0688056L19.4961 1.15097C21.2148 1.25867 22.0029 3.34358 20.7852 4.56127L4.5979 20.7486C3.3802 21.9663 1.2953 21.1781 1.1876 19.4594L0.105431 2.18998Z" fill="url(#paint0_linear_263_513)"></path>
                                                <defs>
                                                    <lineargradient id="paint0_linear_263_513" x1="-0.0363755" y1="-0.0729998" x2="35.3333" y2="-0.0729991" gradientUnits="userSpaceOnUse">
                                                      <stop offset="1" stopColor="var(--tj-theme-primary)"></stop>
                                                      <stop offset="1" stopColor="#140C1C" stopOpacity="0"></stop>
                                                    </lineargradient>
                                                </defs>
                                              </svg>
                                          </div>
                                          <p className="quote">Extremely responsive, delivered the job within the extremely tight deadline we had to impose. Looking forward to hiring him again.</p>
                                          <h4 className="name">Wallace Chuck</h4>
                                          <span className="designation">Senior Software Dev, Cosmic Sport</span>
                                        </div>
                                      </div>
                                      <div className="owl-item cloned" style={{width: '303px' , marginRight: '30px'}}><div className="testimonial-item">
                                          <div className="top-area d-flex flex-wrap justify-content-between">
                                              <div className="logo-box">
                                                <img src="assets/img/2.png" alt="" />
                                              </div>
                                              <div className="image-box">
                                                <img src="assets/img/3(1).jpg" alt="" />
                                              </div>
                                          </div>
                                          <div className="icon-box">
                                              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.105431 2.18998C0.0301532 0.988687 1.02531 -0.00647222 2.2266 0.0688056L19.4961 1.15097C21.2148 1.25867 22.0029 3.34358 20.7852 4.56127L4.5979 20.7486C3.3802 21.9663 1.2953 21.1781 1.1876 19.4594L0.105431 2.18998Z" fill="url(#paint0_linear_263_514)"></path>
                                                <defs>
                                                    <lineargradient id="paint0_linear_263_514" x1="-0.0363755" y1="-0.0729998" x2="35.3333" y2="-0.0729991" gradientUnits="userSpaceOnUse">
                                                      <stop offset="1" stopColor="var(--tj-theme-primary)"></stop>
                                                      <stop offset="1" stopColor="#140C1C" stopOpacity="0"></stop>
                                                    </lineargradient>
                                                </defs>
                                              </svg>
                                              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.105431 2.18998C0.0301532 0.988687 1.02531 -0.00647222 2.2266 0.0688056L19.4961 1.15097C21.2148 1.25867 22.0029 3.34358 20.7852 4.56127L4.5979 20.7486C3.3802 21.9663 1.2953 21.1781 1.1876 19.4594L0.105431 2.18998Z" fill="url(#paint0_linear_263_515)"></path>
                                                <defs>
                                                    <lineargradient id="paint0_linear_263_515" x1="-0.0363755" y1="-0.0729998" x2="35.3333" y2="-0.0729991" gradientUnits="userSpaceOnUse">
                                                      <stop offset="1" stopColor="var(--tj-theme-primary)"></stop>
                                                      <stop offset="1" stopColor="#140C1C" stopOpacity="0"></stop>
                                                    </lineargradient>
                                                </defs>
                                              </svg>
                                          </div>
                                          <p className="quote">U.Signe was a great developer and I enjoyed working with him. His communication and responsiveness were top-notch and his skills were very strong.</p>
                                          <h4 className="name">Jenifer Lopa</h4>
                                          <span className="designation">New Customer</span>
                                        </div>
                                      </div>
                                      <div className="owl-item active" style={{width: '303px' , marginRight: '30px' }}><div className="testimonial-item">
                                          <div className="top-area d-flex flex-wrap justify-content-between">
                                              <div className="logo-box">
                                                <img src="assets/img/1.png" alt="" />
                                              </div>
                                              <div className="image-box">
                                                <img src="assets/img/1(1).jpg" alt="" />
                                              </div>
                                          </div>
                                          <div className="icon-box">
                                              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.105431 2.18998C0.0301532 0.988687 1.02531 -0.00647222 2.2266 0.0688056L19.4961 1.15097C21.2148 1.25867 22.0029 3.34358 20.7852 4.56127L4.5979 20.7486C3.3802 21.9663 1.2953 21.1781 1.1876 19.4594L0.105431 2.18998Z" fill="url(#paint0_linear_263_588)"></path>
                                                <defs>
                                                    <lineargradient id="paint0_linear_263_588" x1="-0.0363755" y1="-0.0729998" x2="35.3333" y2="-0.0729991" gradientUnits="userSpaceOnUse">
                                                      <stop offset="1" stopColor="var(--tj-theme-primary)"></stop>
                                                      <stop offset="1" stopColor="#140C1C" stopOpacity="0"></stop>
                                                    </lineargradient>
                                                </defs>
                                              </svg>
                                              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.105431 2.18998C0.0301532 0.988687 1.02531 -0.00647222 2.2266 0.0688056L19.4961 1.15097C21.2148 1.25867 22.0029 3.34358 20.7852 4.56127L4.5979 20.7486C3.3802 21.9663 1.2953 21.1781 1.1876 19.4594L0.105431 2.18998Z" fill="url(#paint0_linear_263_589)"></path>
                                                <defs>
                                                    <lineargradient id="paint0_linear_263_589" x1="-0.0363755" y1="-0.0729998" x2="35.3333" y2="-0.0729991" gradientUnits="userSpaceOnUse">
                                                      <stop offset="1" stopColor="var(--tj-theme-primary)"></stop>
                                                      <stop offset="1" stopColor="#140C1C" stopOpacity="0"></stop>
                                                    </lineargradient>
                                                </defs>
                                              </svg>
                                          </div>
                                          <p className="quote">Working with the team at U.Signe over the last 5 years has been a brilliant experience on so many levels. Their understanding of my development needs has been great.</p>
                                          <h4 className="name">A.Rahman</h4>
                                          <span className="designation">CEO, Rahman Group</span>
                                        </div>
                                      </div>
                                      <div className="owl-item active" style={{width: '303px' , marginRight: '30px'}}><div className="testimonial-item">
                                          <div className="top-area d-flex flex-wrap justify-content-between">
                                              <div className="logo-box">
                                                <img src="assets/img/2.png" alt="" />
                                              </div>
                                              <div className="image-box">
                                                <img src="assets/img/4(2).jpg" alt="" />
                                              </div>
                                          </div>
                                          <div className="icon-box">
                                              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.105431 2.18998C0.0301532 0.988687 1.02531 -0.00647222 2.2266 0.0688056L19.4961 1.15097C21.2148 1.25867 22.0029 3.34358 20.7852 4.56127L4.5979 20.7486C3.3802 21.9663 1.2953 21.1781 1.1876 19.4594L0.105431 2.18998Z" fill="url(#paint0_linear_263_511)"></path>
                                                <defs>
                                                    <lineargradient id="paint0_linear_263_511" x1="-0.0363755" y1="-0.0729998" x2="35.3333" y2="-0.0729991" gradientUnits="userSpaceOnUse">
                                                      <stop offset="1" stopColor="var(--tj-theme-primary)"></stop>
                                                      <stop offset="1" stopColor="#140C1C" stopOpacity="0"></stop>
                                                    </lineargradient>
                                                </defs>
                                              </svg>
                                              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.105431 2.18998C0.0301532 0.988687 1.02531 -0.00647222 2.2266 0.0688056L19.4961 1.15097C21.2148 1.25867 22.0029 3.34358 20.7852 4.56127L4.5979 20.7486C3.3802 21.9663 1.2953 21.1781 1.1876 19.4594L0.105431 2.18998Z" fill="url(#paint0_linear_263_510)"></path>
                                                <defs>
                                                    <lineargradient id="paint0_linear_263_510" x1="-0.0363755" y1="-0.0729998" x2="35.3333" y2="-0.0729991" gradientUnits="userSpaceOnUse">
                                                      <stop offset="1" stopColor="var(--tj-theme-primary)"></stop>
                                                      <stop offset="1" stopColor="#140C1C" stopOpacity="0"></stop>
                                                    </lineargradient>
                                                </defs>
                                              </svg>
                                          </div>
                                          <p className="quote">We have enjoyed working with U.Signe. They were able to set up everything according to our needs. Good luck to them.</p>
                                          <h4 className="name">James E. Nelson</h4>
                                          <span className="designation">GM, Silver Coin</span>
                                        </div>
                                      </div>
                                      <div className="owl-item" style={{width: '303px' , marginRight: '30px' }}><div className="testimonial-item">
                                        <div className="top-area d-flex flex-wrap justify-content-between">
                                            <div className="logo-box">
                                              <img src="assets/img/1.png" alt="" />
                                            </div>
                                            <div className="image-box">
                                              <img src="assets/img/2(1).jpg" alt="" />
                                            </div>
                                        </div>
                                        <div className="icon-box">
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M0.105431 2.18998C0.0301532 0.988687 1.02531 -0.00647222 2.2266 0.0688056L19.4961 1.15097C21.2148 1.25867 22.0029 3.34358 20.7852 4.56127L4.5979 20.7486C3.3802 21.9663 1.2953 21.1781 1.1876 19.4594L0.105431 2.18998Z" fill="url(#paint0_linear_263_512)"></path>
                                              <defs>
                                                  <lineargradient id="paint0_linear_263_512" x1="-0.0363755" y1="-0.0729998" x2="35.3333" y2="-0.0729991" gradientUnits="userSpaceOnUse">
                                                    <stop offset="1" stopColor="var(--tj-theme-primary)"></stop>
                                                    <stop offset="1" stopColor="#140C1C" stopOpacity="0"></stop>
                                                  </lineargradient>
                                              </defs>
                                            </svg>
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M0.105431 2.18998C0.0301532 0.988687 1.02531 -0.00647222 2.2266 0.0688056L19.4961 1.15097C21.2148 1.25867 22.0029 3.34358 20.7852 4.56127L4.5979 20.7486C3.3802 21.9663 1.2953 21.1781 1.1876 19.4594L0.105431 2.18998Z" fill="url(#paint0_linear_263_513)"></path>
                                              <defs>
                                                  <lineargradient id="paint0_linear_263_513" x1="-0.0363755" y1="-0.0729998" x2="35.3333" y2="-0.0729991" gradientUnits="userSpaceOnUse">
                                                    <stop offset="1" stopColor="var(--tj-theme-primary)"></stop>
                                                    <stop offset="1" stopColor="#140C1C" stopOpacity="0"></stop>
                                                  </lineargradient>
                                              </defs>
                                            </svg>
                                        </div>
                                        <p className="quote">When looking for freelancers you have to kiss a few frogs to find a prince. Now, these guys are the first place I go because I have confidence in their work from start to finish.</p>
                                        <h4 className="name">Jen kim</h4>
                                        <span className="designation">Manager, BizBend</span>
                                      </div>
                                      </div>
                                      </div></div><button role="button" className="owl-dot"><span></span></button><button role="button" className="owl-dot"><span></span></button></div></div>
                                </div>
                            </div>
                          </div>
                    
                </section>
                




                <section className="contact-section" id="contact-section">
                  <div className="container">
                      <div className="row">
                        <div className="col-lg-6 col-md-7 order-2 order-md-1">
                            <div className="contact-form-box wow fadeInLeft" data-wow-delay=".3s" style={{visibility: 'hidden' , animationDelay: '0.3s' , animationName: 'none' }}>
                              <div className="section-header">
                                  <h2 className="section-title">Let’s work together!</h2>
                                  <p>I design and code beautifully simple things and i love what i do. Just simple like that!</p>
                              </div>

                              <div className="tj-contact-form">
                                  <form id="contact-form" noValidate="noValidate">
                                    <div className="row gx-3">
                                        <div className="col-sm-6">
                                          <div className="form_group">
                                              <input type="text" name="conName" id="conName" placeholder="First name" autoComplete="off" />
                                          </div>
                                        </div>
                                        <div className="col-sm-6">
                                          <div className="form_group">
                                              <input type="text" name="conLName" id="conLName" placeholder="Last name" autoComplete="off" />
                                          </div>
                                        </div>
                                        <div className="col-sm-6">
                                          <div className="form_group">
                                              <input type="email" name="conEmail" id="conEmail" placeholder="Email address" autoComplete="off" />
                                          </div>
                                        </div>
                                        <div className="col-sm-6">
                                          <div className="form_group">
                                              <input type="tel" name="conPhone" id="conPhone" placeholder="Phone number" autoComplete="off" />
                                          </div>
                                        </div>
                                        <div className="col-12">
                                          <div className="form_group">
                                              <select name="conService" id="conService" className="tj-nice-select" style={{display: 'none'}}>
                                                <option value="" selected="" disabled="">Choose Service</option>
                                                <option value="braning">Branding Design</option>
                                                <option value="web">Web Design</option>
                                                <option value="uxui">UI/UX Design</option>
                                                <option value="app">App Design</option>
                                              </select><div className="nice-select tj-nice-select" tabindex="0"><span className="current">Choose Service</span><ul className="list"><li data-value="" className="option selected disabled">Choose Service</li><li data-value="braning" className="option">Branding Design</li><li data-value="web" className="option">Web Design</li><li data-value="uxui" className="option">UI/UX Design</li><li data-value="app" className="option">App Design</li></ul></div>
                                          </div>
                                        </div>
                                        <div className="col-12">
                                          <div className="form_group">
                                              <textarea name="conMessage" id="conMessage" placeholder="Message"></textarea>
                                          </div>
                                        </div>
                                        <div className="col-12">
                                          <div className="form_btn">
                                              <button type="submit" className="btn tj-btn-primary">Send Message</button>
                                          </div>
                                        </div>
                                    </div>
                                  </form>
                              </div>
                            </div>
                        </div>

                        <div className="col-lg-5 offset-lg-1 col-md-5 d-flex flex-wrap align-items-center order-1 order-md-2">
                            <div className="contact-info-list">
                              <ul className="ul-reset">
                                  <li className="d-flex flex-wrap align-items-center position-relative wow fadeInRight" data-wow-delay=".4s" style={{visibility: 'hidden' , animationDelay: '0.4s' , animationName: 'none' }}>
                                    <div className="icon-box">
                                        <i className="flaticon-phone-call"></i>
                                    </div>
                                    <div className="text-box">
                                        <p>Phone</p>
                                        <a href="tel:0123456789">+880 1796-227267</a>
                                    </div>
                                  </li>
                                  <li className="d-flex flex-wrap align-items-center position-relative wow fadeInRight" data-wow-delay=".5s" style={{visibility: 'hidden' , animationDelay: '0.5s' , animationName: 'none' }}>
                                    <div className="icon-box">
                                        <i className="flaticon-mail-inbox-app"></i>
                                    </div>
                                    <div className="text-box">
                                        <p>Email</p>
                                        <a href="mailto:mail@mail.com">it.unisigne@mail.com</a>
                                    </div>
                                  </li>
                                  <li className="d-flex flex-wrap align-items-center position-relative wow fadeInRight" data-wow-delay=".6s" style={{visibility: 'hidden' , animationDelay: '0.6s' , animationName: 'none' }}>
                                    <div className="icon-box">
                                        <i className="flaticon-location"></i>
                                    </div>
                                    <div className="text-box">
                                        <p>Address</p>
                                        <a href="#">Silver Coin, Jhautola, <br/>Cumilla, Bd</a>
                                    </div>
                                  </li>
                              </ul>
                            </div>
                        </div>
                      </div>
                  </div>
                </section>

                <div className="modal contact_modal" id="message_sent" tabindex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                              <span className="modal-title"><strong>Message Sent Successfully</strong></span>
                              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></button>
                          </div>
                          <div className="modal-body">
                              <p>Thank you for contacting us. We will get back to you shortly.<br/>Have a great day!</p>
                          </div>
                          <div className="modal-footer">
                              <button type="button" className="btn" data-bs-dismiss="modal">Close</button>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div className="modal contact_modal failed" id="message_fail" tabindex="-1">
                      <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                                <span className="modal-title"><strong>Error</strong></span>
                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></button>
                            </div>
                            <div className="modal-body">
                                <p>Oops! Something went wrong, please try again.</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn" data-bs-dismiss="modal">Close</button>
                            </div>
                          </div>
                      </div>
                    </div>


                    <footer className="tj-footer-area">
                          <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                  <div className="footer-logo-box">
                                      <a href="#"><img src="assets/img/logo.png" alt="" /></a>
                                  </div>
                                  <div className="footer-menu">
                                      <nav>
                                        <ul>
                                            <li><a href="#home-section">Home</a></li>
                                            <li><a href="#services-section">Services</a></li>
                                            <li><a href="#works-section">Works</a></li>
                                            <li><a href="#resume-section">Resume</a></li>
                                            <li><a href="#skills-section">Skills</a></li>
                                            <li><a href="#testimonials-section">Testimonials</a></li>
                                            <li><a href="#contact-section">Contact</a></li>
                                        </ul>
                                      </nav>
                                  </div>
                                  <div className="copy-text">
                                      <p>© 2024 All rights reserved by <a href="https://unisigne.com" target="_blank">UniSigne</a></p>
                                  </div>
                                </div>
                            </div>
                          </div>
                      </footer>

      </main>
    </div>
  )
}

export default Home;
