import React from 'react'

function Header() {
  return (
    <div>
       <div className="preloader" style={{translate: 'none', rotate: 'none' , scale: 'none' , transform: 'translate(0px, -1500px)' , display: 'none' , zIndex: '-1'}}>
          <svg viewBox="0 0 1000 1000" preserveAspectRatio="none">
            <path id="preloaderSvg" d="M0 2S175 1 500 1s500 1 500 1V0H0Z"></path>
          </svg>

          <div className="preloader-heading">
            <div className="load-text" style={{translate: 'none' , rotate: 'none' , scale: 'none' , opacity: '0' , transform: 'translate(0px, -100px)'}}>
                <span>L</span>
                <span>o</span>
                <span>a</span>
                <span>d</span>
                <span>i</span>
                <span>n</span>
                <span>g</span>
            </div>
          </div>
      </div>
      <div className="progress-wrap" id="scrollUp">
          <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" style={{transition: 'stroke-dashoffset 10ms linear 0s' , strokeDasharray: '307.919, 307.919' , strokeDashoffset: '307.919'}}></path>
          </svg>
      </div>


      <header class="tj-header-area header-absolute" id="home-section">
            <div class="container">
              <div class="row">
                  <div class="col-12 d-flex flex-wrap align-items-center">
                    <div class="logo-box">
                        <a href="https://unisigne.com/">
                          <img src="assets/img/logo.png" alt="" />
                        </a>
                    </div>

                    
                    <div class="header-menu">
                        <nav>
                          <ul>
                              <li><a href="#home-section">Home</a></li>
                              <li><a href="#facilities-section">Facilities</a></li>
                              <li><a href="#services-section">Services</a></li>
                              <li><a href="#works-section">Works</a></li>
                              <li><a href="#resume-section">Resume</a></li>
                              <li><a href="#skills-section">Skills</a></li>
                              <li><a href="#testimonials-section">Testimonials</a></li>
                              <li><a href="#contact-section">Contact</a></li>
                          </ul>
                        </nav>
                    </div>
                    <div class="header-button">
                        <a href="#contact-section" class="btn tj-btn-primary">Hire me!</a>
                    </div>
                    <div class="menu-bar d-lg-none">
                        <button>
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                        </button>
                    </div>
                  </div>
              </div>
            </div>
      </header>

      <header class="tj-header-area header-2 header-sticky sticky-out">
            <div class="container">
              <div class="row">
                  <div class="col-12 d-flex flex-wrap align-items-center">
                    <div class="logo-box">
                        <a href="https://unisigne.com/">
                          <img src="assets/img/logo.png" alt="" />
                        </a>
                    </div>

                    

                    <div class="header-menu">
                        <nav>
                          <ul>
                              <li><a href="#home-section">Home</a></li>
                              <li><a href="#facilities-section">Facilities</a></li>
                              <li><a href="#services-section">Services</a></li>
                              <li><a href="#works-section">Works</a></li>
                              <li><a href="#resume-section">Resume</a></li>
                              <li><a href="#skills-section">Skills</a></li>
                              <li><a href="#testimonials-section">Testimonials</a></li>
                              <li><a href="#contact-section">Contact</a></li>
                          </ul>
                        </nav>
                    </div>

                    <div class="header-button">
                        <a href="#contact-section" class="btn tj-btn-primary">Hire me!</a>
                    </div>

                    <div class="menu-bar d-lg-none">
                        <button>
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                        </button>
                    </div>
                  </div>
              </div>
            </div>
      </header>

      







    </div>
  )
}

export default Header
